.productpage_hero_img{
    width: 100%;
    height: 646px;
}
.product_heroimg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(60%);
}
.overlay_product_text {
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
  }
  .product_hero_title{
font-size: 64px;
font-weight: 800;
line-height: 87.16px;
text-align: center;
color: #FFFFFF;
  }
  .product_card_div{
    padding-left: 5%;
    padding-right: 5%;
    position: relative;
    top: -150px;
  }
  .product_brown_card{
    width: 234px;
    height: 314px;
    padding: 3%;
    background-color:#826D46;
    padding: 20px;
  }
  .procard_text{
font-size: 24px;
font-weight: 600;
line-height: 32.68px;
text-align: center;
color: #FFFFFF;
margin-top: 15%;
  }
  .mob_product_div{
    display: none;
  }
  .productpage_heading{
font-size: 36px;
font-weight: 700;
line-height: 49.03px;
text-align: center;
color: #146A7E;
  }
  .rest-words{
    font-size: 36px;
font-weight: 700;
line-height: 49.03px;
text-align: center;
color: #899450;
  }

  .productpage_text{
font-size: 16px;
font-weight: 400;
line-height: 32px;
text-align: center;
color: #494949;
  }
  .work_greendiv{
    width: 100%;
    height: 700px;
    background-color: #D1DE87;
    padding: 24px;
  }
  .work_bluediv{
    width: 100%;
    height: 700px;
    background-color: #B1DEEB;
    padding: 60px;
  }
  .work_img{
    width: 100%;
    height: 100%;
  }
  .work_head{
font-size: 32px;
font-weight: 800;
line-height: 43.58px;
text-align: left;
color: #146A7E;
  }
  .color_work_head{
    font-size: 32px;
font-weight: 800;
line-height: 43.58px;
text-align: left;
color: #899450;
  }
  .work_content{
font-size: 20px;
font-weight: 400;
line-height: 38px;
text-align: left;
color: #000000;
  }
  .app_list_div{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .list_text{
font-size: 16px;
font-weight: 400;
line-height: 21.79px;
text-align: left;
color: #494949;
  }
.browndivimg{
  width: 100px;
  margin-left: 25%;
}
  @media (max-width:1024px) {
    .work_greendiv{
      height: 650px;
      padding: 20px;
    }
    .work_bluediv{
      height: 650px;
      padding: 20px;
    }
    .work_content{
      font-size: 18px;
      line-height: 30px;
        }
        
  }

  @media (max-width:992px) {
    .productpage_hero_img{
      width: 100%;
      height: 436px;
  }
  .overlay_product_text {
    top: 40%; 
  }
    .product_hero_title{
      font-size: 32px;
      line-height: 60px;
        }
        .product_card_div{
          top: -90px;
        }
        .product_brown_card{
          width: 100% !important;
          height: 214px;
        }
        .procard_text{
          font-size: 18px;
          line-height: 26px;
            }
            .productpage_heading{
              font-size: 28px;
              line-height: 46px;
                }
                .rest-words{
                  font-size: 28px;
              line-height: 46px;
                }
                
  .productpage_text{
    font-size: 14px;
    line-height: 25px;
      }
      .list_text{
        font-size: 12px;
          }
          .work_greendiv{
            height: 580px;
            padding: 20px;
          }
          .work_bluediv{
            height: 580px;
            padding: 20px;
          }
          .work_head{
            font-size: 28px;
           
              }
              .color_work_head{
                font-size: 28px;
              }
              .work_content{
                font-size: 14px;
                line-height: 25px;
                  }
  }

  @media (max-width:480px) {
    .productpage_hero_img{
        width: 100%;
        height: 300px;
    }
    .product_hero_title{
font-size: 28px;
line-height: 38.13px;
          }
          .overlay_product_text {
            top: 20%;
        }

    .product_card_div{
        display: none;
    }
    .mob_product_div{
        display: block;
        position: relative;
        top: -90px;
      }
      .product_brown_card{
        width: 234px !important;
        height: 270px;
      }
      .procard_text{
        font-size: 18px;
line-height: 26px;
      }
      .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after,
      .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after 
     {
        font-size: 24px !important;
        font-weight: 800 !important;
        color: #146A7E;
    }
    .productpage_heading{
      font-size: 18px;
      line-height: 21.79px;
        }
        .rest-words{
          font-size: 18px;
      line-height: 21.79px;
        }
        .productpage_text{
          font-size: 12px;
          line-height: 22px;
            }
    .app_list_div{
    flex-direction: column-reverse !important;
    }
    .work_greendiv{
      margin-top: 10%;
      height: 285px;
      padding: 0px;
    }
    .work_bluediv{
      height: auto;
      padding: 20px;
    }
    .work_head{
      font-size: 20px;
      text-align: center;
      line-height: 27.24px;
     
        }
        .color_work_head{
          font-size: 20px;
          text-align: center;
          line-height: 27.24px;
        }
        .work_content{
          font-size: 12px;
          line-height: 24px;
            }
  }