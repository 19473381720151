.sign_main_div{
    width: 550px;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .admin_logo{
    width: 150px;
    height: 61px;
    /* object-fit: contain; */
    position: relative;
    left: 20px;
  }
  .sign_input_field{
    width: 100%;
    height: 52px;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    margin-top: 5%;
    padding-left: 5%;
    color: #303030;
  }
  .sign_checkbox{
    width: 20px;
    height: 20px;
    margin-top: 5%;
  }
  .check_text{
    font-size: 14px;
    font-weight: 400;
    line-height: 32.68px;
    text-align: left;
    margin-left: 2%;
    color: #303030; 
    margin-top: 4%;
  }
  .create_blog_head{
  font-size: 40px;
  font-weight: 400;
  line-height: 54.47px;
  text-align: center;
  color: #000000;
  }
  .field_div{
    margin-top: 3%;
  }
  .label_text{
  font-size: 17px;
  font-weight: 400;
  line-height: 27.24px;
  text-align: left;
  color: #303030;
  }
  .sign_submit_btn{
    margin-top: 10%;
    width: 100%;
    height: 50px;
    background-color: #33271A;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
  }
  .inputform {
    border: 1px solid #5D5D5D;
    width: 100%;
    height: 50px;
    padding-left: 2%;
    font-size: 14px;
  }
  .inputdescr{
    border: 1px solid #5D5D5D;
    width: 100%;
    padding-top: 1%;
    /* height: 150px; */
    padding-left: 2%;
    font-size: 14px;
  }
  
  .side {
    width: 15%;
    height: 100vh;
    background-color: #33271a;
    padding-top: 80px;
  }
  .sticky_sidebar{
    position: -webkit-sticky;
    position: sticky;
    top: 20%;
  }
  .sidelinks.active1 {
    background-color: #b1deeb;
  }
  
  .sideabove {
    width: 100%;
    height: 80px;
    background-color: #33271a;
    position: fixed;
    top: 0px;
    z-index: 999;
  }
  .sidehead {
    font-size: 28px;
    font-weight: 500;
    color: white;
    text-align: center;
  }
  .sidelinks {
    width: 100%;
    height: 40px;
    background-color: #d1de87;
    text-align: left;
    font-size: 20px;
    font-weight: 700;
    line-height: 27.24px;
    margin-bottom: 10px;
    padding-left: 25%;
    padding-top: 7px;
    color: black !important;
  }
  .sidelinks:hover {
    background-color: #b1deeb;
  }
  .sidetext {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: left;
    color: white;
    position: relative;
    right: 20px;
   top: 8px;
  }
  .main-content {
  left: 15%;
    height: auto;
    top: 15%;
    position: absolute;
    padding: 10px 40px 20px 40px;
  }
  
  .create_btn{
    margin-top: 1%;
    width: 150px;
    height: 50px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    background-color: #74613F;
  }
  
  .panelbutt {
    background-color: #0e3f4d;
    color: white;
    width: 216px;
    height: 64px;
    margin-right: 20px;
  }
  .button-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .adminhead {
    font-size: 32px;
    font-weight: 700;
    line-height: 49.03px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th {
  font-size: 24px;
  font-weight: 400;
  line-height: 32.68px;
  text-align: left;
  color: #3C3B3B;
  }
  td {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  color: #555555;
  }
  
  th,
  td {
    padding: 15px;
    text-align: left;
    border: none;
  }
  .dlt_btn{
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  color: #E80505;
  }
  
  tr {
    border-bottom: 1px solid #ddd;
  }
  
  thead tr {
    border-bottom: 2px solid #ddd;
  }
  
  tbody tr:last-child {
    border-bottom: none; 
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 50px ;
  }
  
  .modal {
    display: flex !important;
      justify-content: center !important;
      position: fixed !important;
      overflow: auto !important;
      scrollbar-width: none !important ;
      background-color: rgba(0, 0, 0, 0.6) !important;
      z-index: 10 !important;
      padding-top: 6% !important;
      height: 100% !important;
  }
  .modal-admincontent {
    background: #FFFFFF !important;
    padding: 20px !important;
    border: 1px solid #888 !important;
    width: 90% !important;
   position: relative;
    height: 100% !important ;
    overflow-y: scroll !important;
    scrollbar-width: none !important;
  }
  .close {
    color: black;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 0;
    right:  20px;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .sn_div{
    width: 100px;
  }
  .title_div{
    width: 800px;
  }
  
  .action_div{
    width: 100px;
  }
  .prev_pagination{
    width: 100px;
    height: 50px;
    background-color: #FFFFFF !important;
    color: #000000 !important;
    border: 1px solid #000000 !important;
  }
  .paginate_btn{
    background-color: #000000 !important;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  @media (max-width:1024px) {
   
    .sn_div{
      width: 70px;
      
    }
    .title_div{
      width: 600px;
    }
    .action_div{
      width: 80px;
    }
    .modal {
        padding-top: 10%;
    }
  }
  
  @media (max-width:992px) {
   
    .sn_div{
      width: 70px;
      
    }
    .title_div{
      width: 600px;
    }
    .action_div{
      width: 80px;
    }
    th {
      font-size: 20px;
      }
      td {
      font-size: 14px;
      }
      .dlt_btn{
        font-size: 14px;
        }
        .sidelinks {
          font-size: 16px;
        }
        .create_blog_head{
          font-size: 28px;
          line-height: 54.47px;
          }
        .modal {
          padding-top: 12%;
      }
  }
  @media (max-width:480px) {
    .modal {
      padding-top: 20%;
  }
  }