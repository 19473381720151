/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

/* Tailwind CSS directives */
/* @tailwind base;
@tailwind components;
@tailwind utilities; */

:root {
  --swiper-navigation-size: 18px;
}

.swiper-button-prev,
.swiper-button-next {
  height: 30px;
  width: 30px;
  color: white !important;
  cursor: pointer;
  border: 2px white solid !important;
  border-radius: 50%;
  padding: 10px !important;
}
.swiper-container {
  transition: transform 1s ease-in-out;
}

.clientimg {
  transition: transform 1s ease-in-out;
}

.video-container {
  width: 100%;
  height: 100vh !important;
}
.sliderbackvideo {
  position: absolute;
  top: 14%;
  left: 14%;
  padding-left: 72px;
  padding-right: 70px;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  width: 72%;
  height: 370px;
  background-color: rgba(255, 255, 255, 0.7);
}
.herosec {
  background-image: url("../public/herosecimg.webp");
  filter: brightness(90%);
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
}
.sliderback {
  width: 72%;
  height: 370px;
  background-color: rgba(255, 255, 255, 0.7);
  position: relative;
  top: 14%;
  left: 14%;
  padding-left: 72px;
  padding-right: 70px;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
.herohead,
.herohead2 {
  font-weight: 700;
  text-align: left;
}
.herohead {
  font-size: 32px;
  line-height: 1.53;
  color: #16657d;
  padding-top: 45px;
}
.herohead2 {
  font-size: 48px;
  line-height: 0.52375;
  color: #576605c2;
}
.dropletimg {
  width: 41.74px;
  height: 42px;
  margin-top: 44px;
  margin-left: 5px;
  object-fit: contain;
}
.hometext {
  font-size: 17px;
  font-weight: 550;
  line-height: 1.65;
  text-align: left;
  margin-top: 25px;
}
.slidertext {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.14;
  text-align: left;
  color: #333030;
}
.slidertext1 {
  margin-top: 6%;
}
.see_more_btn {
  width: 122px;
  height: 37px;
  background-color: #816c46;
}
.see_more_btn:hover {
  background-color: #000000;
}
.getinbutt,
.getinbutt1,
.getinbutt2 {
  background-color: #816c46;
  color: white;
  height: 35px;
  font-size: 14px;
  font-weight: 300;
  line-height: 32px;
  padding: 2px 12px;
  text-decoration: none !important;
}
.getinbutt {
  margin-top: 15px;
  width: 122px;
}
.getinbutt:hover {
  background-color: #000000;
}
.getinbutt1 {
  width: 110px;
}
.getinbutt2 {
  width: 95px;
}
.getinbutt2:hover {
  background-color: #000000;
}
.arrowicon {
  color: white;
}
.arrow1 {
  color: white;
  position: relative;
  top: 1px;
  height: 30px;
  width: 16px;
  left: -40px;
}
.see_text {
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
}
.arrow {
  width: 13px;
  transform: rotate(-50deg);
  position: relative;
  left: 5px;
}
.databack {
  background-color: #d0eef652;
}
.datatext {
  font-size: 0.8em;
  font-weight: 520;
  line-height: 24.51px;
  text-align: left;
  margin-top: 10px;
}
.datalogo {
  width: 30px;
  height: 30px;
  margin: 0px 15px 0 0;
  object-fit: contain;
}
.datalogo1 {
  margin-left: -50px;
}
.containers1 {
  padding: 40px 52px;
  position: relative;
}
.homeabouthead {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.44;
  text-align: left;
  color: #14667e;
}
.ellipse {
  background-image: url("../public/ellipse.webp");
  background-repeat: no-repeat;
  background-size: contain;
}
.homeabouthead1 {
  color: rgba(87, 102, 5, 0.7);
}
.homeaboutsubhead {
  font-size: 20px;
  font-weight: 550;
  line-height: 1.6;
  text-align: left;
  color: #027d8e;
}
.homeabouttext {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #494949;
  margin-top: 10px;
}
.abt_wrap{
  position: relative;
}
.homeabout1 {
  width: 356px;
  height: 351px;
  top: 100px;
  position: absolute;
  right: 200px;
  z-index: 1;
  object-fit: contain;
}
.homeabout2 {
  width: 403px;
  height: 424px;
  position: absolute;
  right: 0px;
  z-index: 0;
  object-fit: contain;
}
.ourapproach {
  background-color: #d0eef6;
  width: 100%;
  height: 250px;
  padding: 35px;
}
.ourapproach1 {
  background-color: #d1de87cc;
  width: 100%;
  height: 250px;
  padding: 35px;
}
.app_text_div1 {
  margin-top: 30px;
}
.ouricon {
  width: 50.63px;
  height: 44px;
  margin-top: 5px;
  margin-right: 5px;
}
.section3text {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #494949;
}
.servcard {
  width: 100%;
  height: 430px;
  border: 1px solid #c1bdbd;
  background-color: #ffffff;
}
.servtitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #14667e;
  padding: 10px;
  margin-top: 20px;
}
.servtext {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: center;
  color: #5a5959;
  padding: 0px 10px;
  margin-bottom: 20px;
  height: auto;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.servanchor {
  font-size: 16px;
  font-weight: 700;
  line-height: 5px;
  text-align: center;
  color: #816c46 !important;
  position: relative;
  bottom: 10px;
}
.servanchor:hover {
  text-decoration: 1px underline #816c46 !important;
}
.servmore_div {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.see_more_text {
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  color: #816c46 !important;
}
.servanchor1 {
  margin-top: 2%;
  font-size: 24px;
  font-weight: 600;
  line-height: 5px;
  text-align: center;
  color: #816c46 !important;
}
.servanchor1:hover {
  text-decoration: 1px underline #816c46 !important;
}
.greartericon {
  width: 20.91px;
  height: 15.43px;
  color: #816c46;
  margin-top: -4px;
}
.servlogo {
  width: 50.97px;
  height: 59px;
  position: absolute;
  bottom: -30px;
  right: 0px;
}
.ourhead {
  color: #303030;
  font-size: 1em;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
}
.sectorsbackd {
  background-image: linear-gradient(254.15deg, #ffffff 21.56%, #d1de87 100.89%);
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
}
.sectorsimg {
  width: 493px;
  height: 550px;
  object-fit: cover;
  position: absolute;
  bottom: 0;
}
.sectorsimg1 {
  width: 313px;
  height: 370px;
  position: absolute;
  bottom: 0px;
  right: 15px;
  object-fit: cover;
}
.sector_img_div {
  width: 120px !important;
  height: 80px !important;
  margin-right: 30px;
}
.sectorsimg2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.projectsimg2 {
  width: 120px !important;
  height: 80px !important;
  aspect-ratio: 3/2;
  object-fit: contain;
}
.projectsimg2div {
  width: 110px;
  height: 96px;
  border: 1px solid #c1bdbd;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px #00000005;
}
.homesectorstext {
  font-size: 12px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
  color: #494949;
}
.projectsback {
  background-image: linear-gradient(253.03deg, #b0dae6 28.52%, #ffffff 65.83%);
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
}
.projectimg {
  width: 100%;
  height: 560px;
  object-fit: cover;
}
.projectimg1 {
  width: 270px;
  height: 390px;
  position: absolute;
  left: -50px;
  bottom: 25px;
  object-fit: contain;
}
.blogtext {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.07px;
  text-align: left;
  color: #494949;
}
.blogtext1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.07px;
  text-align: left;
  color: #494949;
}
.blogcontainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.blogdiv {
  width: 100%;
  position: relative;
  height: 280px;
}
.blogimg {
  width: 100%;
  height: 280px;
  object-fit: cover;
  z-index: 0;
}
.blogdiv::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 280px;
  background: #06426786;
  z-index: 1;
}
.overlay-blog {
  position: absolute;
  top: 170px;
  left: 20px;
  width: 100%;
  height: 100%;
  justify-content: left;
  z-index: 2;
  color: white;
}
.blog-headtxt {
  font-size: 16px;
  font-weight: 800;
  line-height: 21.79px;
  letter-spacing: 0.05em;
  text-align: left;
  width: 90%;
  color: #ffffff;
}
.blog_readbtn {
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  margin-top: 2%;
  text-decoration: underline;
  cursor: pointer;
  color: white;
}
.homebloganchor {
  font-size: 12px;
  font-weight: 400;
  line-height: 0.34px;
  text-align: left;
  text-decoration: none;
  color: #816c46;
}
.greartericon1 {
  width: 10.91px;
  height: 9.43px;
}
.servcardimg {
  width: 100%;
  height: 195px;
}
.service_textdiv {
  width: 100%;
  height: 170px;
}
.blogmidimg {
  width: 100%;
  height: 250.07px;
  object-fit: cover;
}
.clientcontainer {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 20px;
}
.clientimg {
  width: 170px;
  height: 111px;
  aspect-ratio: 3/2;
  object-fit: contain;
}
.clientcard {
  width: 100%;
  height: 340px;
  background-color: #b1deeb;
  position: relative;
}
.clientcard1 {
  width: 100%;
  height: 340px;
  border: 1px black solid;
  border-top-left-radius: 150px;
  background-color: white;
  z-index: 1;
  position: relative;
  padding: 20px;
}
.testimonial_div {
  width: 100%;
  height: 100px;
}
.testimonial_text_div {
  width: 100%;
  height: 180px;
}
.testimonial_star_div {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testdp {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 10px;
  top: 15px;
  object-fit: contain;
  border-radius: 50%;
}
.testhead {
  font-size: 14px;
  font-weight: 600;
  line-height: 29.96px;
  text-align: center;
  padding-left: 30%;
  padding-right: 0%;
}
.testsubhead {
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  padding-left: 30%;
}
.testtext {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
}
.staricon {
  width: 18px;
  height: 16px;
}
.treeicon {
  width: 15px;
  height: 14px;
  margin-left: 10px;
}
.abouthead1 {
  color: #14667e;
  font-size: 28px;
  font-weight: 700;
  line-height: 52px;
  text-align: center;
}
.abouttext {
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}
.colouredhead {
  color: #899450;
}
.containers2 {
  padding: 0 120px;
}
.inputform {
  width: 100%;
  border: 1px rgba(107, 107, 107, 0.016) solid;
}
.overlay_text {
  position: absolute;
  top: 60%;
  left: 40%;
  transform: translate(-50%, -50%);
}
.blog_hero_title {
  color: #ffffff;
  font-size: 64px;
  font-weight: 800;
  line-height: 87.16px;
  text-align: left;
}
.hero_stxt {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  line-height: 32.68px;
  text-align: center;
}
.latest_stxt {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  text-transform: uppercase;
  color: #576605;
}
.blogpage_hero_img {
  width: 100%;
  height: 646px;
}
.blog_heroimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
}
.blogpage_img1 {
  width: 100%;
  height: 390px;
}
.blog_img1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blogpage_img2 {
  width: 100%;
  height: 169px;
}
.blog_img2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blogpage_content {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
}
.blogpage_points {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  color: #5a5959;
}
.sticky_div {
  position: -webkit-sticky;
  position: sticky;
  top: 12%;
}
.wapp_cont {
  width: 100%;
  height: 260px;
  background-color: #14667e;
  padding: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog_des {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
}
.latest_head {
  margin-top: 5%;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  color: #000000;
}
.blogpage_img3 {
  width: 100%;
  height: 80px;
}
.blog_img3 {
  width: 100%;
  height: 100%;
}
.page_section_2 {
  padding-bottom: 5%;
  padding-left: 5% !important;
}

.page_section_1 {
  padding-bottom: 5%;
}
.related_title {
  font-size: 20px;
  font-weight: 600;
  line-height: 27.24px;
  text-align: left;
  color: #000000;
}
.relatedpost_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 100px;
}
.relatedpost_imgdiv {
  width: 100%;
  height: 140px;
}
.related_img {
  width: 100%;
  height: 100%;
}
.relatedpost_head {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: left;
  color: #000000;
}
.relatedpost_text {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  color: #494949;
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; 
           line-clamp: 3;
   -webkit-box-orient: vertical;
}
.readpost_btn {
  width: 114px;
  height: 31px;
  background-color: #5a5959;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}
.comment_container {
  width: 100%;
  height: 600px;
  box-shadow: 0px 0px 4px 3px #0000000d;
  margin-top: 10%;
}
.comment_title {
  padding-top: 5%;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: #5a5959;
}
.inputfield_container {
  padding-left: 12%;
  padding-right: 12%;
}
.name_input_field {
  width: 49%;
  height: 50px;
  border: 1px solid #c5c3c3;
  font-size: 20px;
  font-weight: 400;
  padding-left: 3%;
  line-height: 27.24px;
  text-align: left;
  color: #716e6e;
}
.message_input_field {
  width: 100%;
  height: 205px;
  border: 1px solid #c5c3c3;
  font-size: 20px;
  font-weight: 400;
  padding: 3%;
  line-height: 27.24px;
  text-align: left;
  color: #716e6e;
}
.comment_btn {
  width: 187px;
  height: 48px;
  background-color: #816c46;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  line-height: 27.24px;
  text-align: center;
}
.searchinput {
  width: 100%;
  height: 38px;
  border: 1px solid #5a5959b2;
  color: #8c8b8b;
  padding-left: 2%;
  margin-bottom: 5%;
}
.latest_cont {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  color: #000000;
}
a {
  color: inherit !important;
  text-decoration: none !important;
}
.mob_div {
  display: none;
}
.mob_servicecard_div {
  display: none;
}
.approach_mob_div {
  display: none;
}
.mob_blogcontainer {
  display: none;
}
.testimonial_mob_div {
  display: none;
}
.prohomecard {
  width: 100%;
  height: 100%;
  border: 1px solid #c1bdbd;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.prohomecardinner{
  position: relative;
}
.prohomecard .card-body {
  flex: 1;
}
.prohomecardimg {
  width: 100%;
  height: 200px;
}
.promore_div {
  width: 100%;
  height: 30px;
}
.homepro_mob_div {
  display: none;
}
.approach_desk_div {
  margin-top: 5%;
}

@media (min-width: 1440px) {
  .sectorsimg1 {
    width: 313px;
    height: 370px;
    position: relative;
    bottom: -210px;
    left: 260px;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
@media (max-width: 1350px) {
  .ourapproach {
    height: 250px;
    padding: 20px;
  }
  .ourapproach1 {
    height: 250px;
    padding: 20px;
  }
}
@media (max-width: 1241px) {
  .slidertext1 {
    margin-top: 4% !important;
  }
  .homeabout1 {
    width: 320px !important;
    height: 320px;
    right: 160px;
  }
  .homeabout2 {
    width: 370px !important;
    height: 390px;
  }
  .slidertext {
    font-size: 12px;
  }
  .datatext {
    font-size: 0.6em;
  }
  .homeabouthead {
    font-size: 30px;
    line-height: 46px;
  }
  .ourapproach {
    width: 100% !important;
    height: 250px;
    padding: 20px;
  }
  .ourapproach1 {
    width: 100% !important;
    height: 250px;
    padding: 20px;
  }
  .app_text_div1 {
    margin-top: 40px;
  }
  .ouricon {
    width: 40px;
    height: 30px;
    margin-right: 5px;
  }
  .ourhead {
    color: #494949;
    font-size: 1em;
    line-height: 20px;
  }
  .section3text {
    line-height: 22px;
  }
  .servcard {
    height: 450px !important;
  }
  .servcardimg {
    width: 100%;
    height: 170px;
  }

  .service_textdiv {
    width: 100%;
    height: 220px;
  }
  .servmore_div {
    width: 100%;
    height: 60px;
  }
  .sectorsimg {
    width: 70%;
    height: 480px;
  }
  .testdp {
    width: 55px;
    height: 50px;
  }
  .testimonial_text_div {
    width: 100%;
    height: 210px;
  }
  .testhead {
    font-size: 12px;
    line-height: 22px;
    padding-left: 42%;
    margin-top: 5%;
  }
  .testsubhead {
    font-size: 12px;
    line-height: 15px;
  }

  .inputfield_container {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .relatedpost_container {
    grid-gap: 40px;
  }
  .page_section_2 {
    padding-left: 3% !important;
  }
  .blog_des {
    font-size: 22px;
    line-height: 35px;
  }
  .projectimg {
    height: 530px;
  }
  .sol_head {
    font-size: 16px !important;
  }
  .projectimg1 {
    width: 230px;
    height: 350px;
    left: -10px;
    bottom: 50px;
  }
  .clientcard {
    height: 370px;
  }
  .clientcard1 {
    height: 370px;
    padding: 10px;
  }
  .testimonial_div {
    width: 100%;
    height: 100px;
  }
}

@media (max-width: 992px) {
  .sliderback {
    height: auto;
    top: 20%;
    left: 14.5%;
    padding-left: 25px;
    padding-right: 25px;
  }
  .sliderbackvideo {
    height: auto;
    top: 20%;
    left: 14.5%;
    padding-left: 25px;
    padding-right: 25px;
  }
  .slidertext {
    font-size: 9px;
  }
  .hometext {
    font-size: 12px;
  }
  .herohead {
    font-size: 30px;
    padding-top: 25px;
  }
  .herohead2 {
    font-size: 36px;
    line-height: 13.09px;
  }
  .datalogo1 {
    margin-left: 40px;
  }
  .datatext {
    font-size: 0.8em;
  }
  .dropletimg {
    width: 36.74px;
    height: 36px;
    margin-top: 28px;
    margin-left: 0px;
  }
  .containers1 {
    padding: 20px 25px;
  }
  .homeabout1 {
    width: 220px !important;
    height: 250px;
    top: 150px;
    right: 120px;
  }
  .homeabout2 {
    width: 250px !important;
    height: 320px;
    top: 40px;
  }
  .homeaboutsubhead {
    font-size: 16px;
    line-height: 24px;
  }
  .homeabouttext {
    font-size: 12px;
    line-height: 21px;
  }
  .ourapproach {
    width: 100% !important;
    height: 300px;
    margin-bottom: 20px;
  }
  .ourapproach1 {
    width: 100% !important;
    height: 300px;
    padding: 30px;
    margin-bottom: 20px;
  }
  .projectimg {
    width: 80%;
    position: relative;
    margin-bottom: 10px;
    margin-top: 20px;
    margin-left: 10%;
}
.projectimg1 {
  width: 300px;
  height: 410px;
  bottom: 5px;
  z-index: 1;
  left: 20px;
}
.servlogo {
  right: 15px;
  }
  .sectorsimg {
    width: 80%;
    height: 470px;
  }
  .homesectorstext {
    font-size: 9px;
  }
  .servcard {
    height: 400px;
    margin-bottom: 5%;
  }
  .sectorsimg1 {
    width: 236px;
    height: 330px;
    position: absolute;
    right: 0px;
  }
  .blogmidimg {
    width: 280px;
    height: 186px;
  }
  .homebloghead {
    font-size: 10px;
    font-weight: 600;
    line-height: 16.34px;
    text-align: left;
  }
  .clientcontainer {
    grid-template-columns: repeat(4, 1fr);
  }

  .testhead {
    font-size: 18px;
    padding-left: 0;
    margin-left: 0px;
  }
  .testsubhead {
    font-size: 16px;
    padding-left: 0;
  }
  .abouttext {
    font-size: 11px;
    line-height: 20px;
  }
  .containers2 {
    padding: 0 60px;
  }
  .blog-headtxt {
    width: 80%;
  }
  .overlay {
    top: 140px;
  }
  .herosec {
    height: 85vh;
  }
  .containers2 {
    padding: 0 60px;
  }
  .blogpage_hero_img {
    height: 500px;
  }
  .blog_hero_title {
    font-size: 44px;
    font-weight: 800;
    line-height: 55.16px;
  }
  .hero_stxt {
    font-size: 16px;
  }
  .blogpage_img1 {
    height: 300px !important;
  }
  .blogpage_img2 {
    width: 100%;
    height: 150px;
  }
  .blogpage_content {
    font-size: 12px;
    line-height: 18.79px;
  }
  .related_title {
    font-size: 18px !important;
  }
  .relatedpost_head {
    font-size: 12px;
    line-height: 15.07px;
  }
  .readpost_btn {
    width: 100px;
    font-size: 12px;
    font-weight: 500;
  }
  .name_input_field {
    height: 40px;
    font-size: 14px;
    padding-left: 3%;
  }
  .message_input_field {
    height: 180px;
    font-size: 14px;
  }
  .comment_btn {
    width: 150px;
    height: 45px;
    color: #ffffff;
    font-size: 14px;
  }
  .comment_container {
    height: 500px;
  }
  .comment_title {
    padding-top: 5%;
    font-size: 24px;
  }
  .searchinput {
    margin-bottom: 10%;
  }
  .sticky_div {
    top: 14%;
  }
  .wapp_cont {
    width: 100%;
    height: 200px;
    background-color: #14667e;
    padding: 6%;
  }
  .blog_des {
    font-size: 18px;
    line-height: 28px;
  }
  .page_section_2 {
    padding-left: 1% !important;
  }
  .latest_stxt {
    font-size: 10px;
  }
  .latest_cont {
    font-size: 10px;
    line-height: 14.34px;
  }
  .clientcard {
    height: 350px;
  }
  .clientcard1 {
    height: 350px;
    padding: 10px;
  }
  .testimonial_div {
    width: 100%;
    height: 80px;
  }
  .testimonial_text_div {
    width: 100%;
    height: 180px;
    padding: 10px;
  }
  .servanchor1 {
    margin-top: 1%;
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .homeabout1 {
    width: 250px !important;
    top: 75px;
    right: -150px;
    position: relative;
}
  .homeabout2 {
    width: 350px !important;
    height: 300px;
    top: 20px;
    right: 100px;
  }
  .testimonial_mob_div {
    display: block;
  }
  .testimonial_desk_div {
    display: none;
  }
  .sectorsimg {
    position: relative;
}
.page_section_2 {
  display: none;
}
.overlay_text {
  top: 35%;
}
}
@media (max-width: 480px) {
  .homepro_desk_div {
    display: none;
  }
  .homepro_mob_div {
    display: block;
    margin-top: 10%;
    margin-bottom: 15%;
  }
  .containers1 {
    padding: 0px 25px;
  }
  .approach_desk_div {
    display: none;
  }
  .approach_mob_div {
    display: block;
    margin-top: 25%;
    margin-bottom: 12%;
  }
  .desk_servcard_div {
    display: none;
  }
  .mob_servicecard_div {
    display: block;
  }
  .mob_div {
    display: block;
  }
  .desk_swip {
    display: none;
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after,
  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    color: #ffffff;
    font-size: 18px;
    font-weight: 800;
  }
  .swiper-button-prev,
  .swiper-button-next {
    height: 30px;
    width: 30px;
    color: white !important;
    cursor: pointer;
    border: none !important;
    border-radius: 50%;
    padding: 10px !important;
  }
  .projectsback {
    padding-top: 5% !important;
    margin-bottom: 15%;
  }
  .herohead {
    font-size: 20px;
    font-weight: 700;
    line-height: 12.24px;
  }
  .dropletimg {
    width: 20.74px;
    height: 20px;
    margin-top: 20px;
  }
  .herohead2 {
    font-size: 28px;
    line-height: 28.09px;
  }
  .hometext {
    font-size: 10px;
    line-height: 15px;
  }
  .sliderbackvideo {
    top: 15%;
    padding-left: 25px;
    padding-right: 25px;
    height: auto;
  }
  .sliderback {
    top: 15%;
  }
  .slidertext {
    font-size: 9px;
    line-height: 16px;
  }
  .datatext {
    font-size: 1em;
    line-height: 17.51px;
  }
  .datalogo {
    margin: 10px 15px 0 0;
    margin-left: 15%;
  }
  .datalogo1 {
    margin-left: 15%;
  }
  .herosec {
    height: 50vh;
  }
  .ourapproach {
    height: 280px;
    padding: 5% 10%;
    margin-bottom: 10px;
  }
  .ourapproach1 {
    height: 280px;
    padding: 5% 10%;
    margin-bottom: 10px;
  }
  .homeabout1 {
    width: 240px !important;
    top: 70px;
    left: 0;
  }
  .homeabout2 {
    width: 80% !important;
    height: 290px;
    right: 0;
  }
  .homeabouthead {
    font-size: 24px;
    line-height: 27.24px;
    margin-top: 20px;
  }
  .sectorsimg {
    width: 100%;
    height: 450px;
  }
  .sectorsimg1 {
    width: 190px;
    height: 330px;
    position: absolute;
    bottom: 0;
    right: 0px;
  }
  .projectimg {
    width: 100%;
    height: 400px;
      margin-top: 0px;
      margin-left: 0%;
  }
  .projectimg1 {
    width: 270px;
    height: 300px;
    left: -35px;
    bottom: 0px;
  }
  .servcard {
    width: 100%;
    height: 420px !important;
  }
  .servcardimg {
    width: 100%;
    height: 200px;
  }
  .service_textdiv {
    width: 100%;
    height: 150px;
  }
  .servmore_div {
    height: 50px;
  }
  .blogcontainer {
    grid-template-columns: 1fr;
  }
  .blogmidimg {
    width: 100%;
    height: auto;
  }
  .blogdiv::before {
    width: 100%;
    height: 260px;
  }
  .blogimg {
    height: 260px;
  }
  .abouttext {
    font-size: 15px;
    line-height: 26px;
  }
  .containers2 {
    padding: 0 30px;
  }
  .databack {
    background-color: #d0eef652;
    padding: 20px 30px !important;
  }
  .ouricon {
    width: 45.63px;
    height: 35px;
  }
  .relatedpost_container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
  }
  .overlay_text {
    top: 26%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5%;
    width: 100%;
  }
  .blog_hero_title {
    font-size: 24px;
    line-height: 32.68px;
  }

  .hero_stxt {
    font-size: 10px;
    line-height: 16.34px;
  }
  .blogpage_hero_img {
    height: 300px;
  }
  .blogpage_img1 {
    height: 150px !important;
  }
  .blogpage_content {
    font-size: 12px;
    line-height: 18px;
  }
  .blogpage_points {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 3%;
  }
  .blogpage_img2 {
    width: 100%;
    height: 150px;
  }
  .relatedpost_imgdiv {
    width: 100%;
    height: 150px;
  }
  .comment_title {
    font-size: 24px;
  }
  .name_input_field {
    height: 40px;
    font-size: 16px;
  }
  .message_input_field {
    height: 180px;
    font-size: 16px;
    padding: 3%;
  }
  .comment_container {
    height: 620px;
    margin-top: 10%;
  }
  .clientcard {
    height: 300px;
  }
  .clientcard1 {
    height: 300px;
    padding: 20px;
  }
  .testhead {
    font-size: 18px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .testimonial_div {
    width: 100%;
    height: 60px;
  }
  .testimonial_text_div {
    width: 100%;
    height: 180px !important;
  }
  .testimonial_star_div {
    height: 20px;
  }
  .flexii {
    flex-direction: column-reverse !important;
  }
  .mob_blogcontainer {
    display: block;
  }
  .blogcontainer {
    display: none;
  }

    .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 38%;
  }
  .clientimg {
    width: 100px;
    height: 100px;
   
  }
}
@media (max-width: 376px) {
  .homeabout2 {
    width: 200px !important;
    height: 240px;
  }
  .homeabout1 {
    width: 210px !important;
    height: 184px;
  }
  .sliderback {
    top: 5%;
  }
  .herosec {
    height: 55vh;
  }
  .ourapproach {
    height: 320px;
  }
  .ourapproach1 {
    height: 320px;
  }
  .testimonial_star_div {
    height: 40px;
  }
  .clientcard {
    height: 320px;
  }
  .clientcard1 {
    height: 320px;
    padding: 20px;
  }
}
@media (max-width: 321px) {
  .homeabout2 {
    width: 200px !important;
    height: 210px;
    right: 90px;
  }
  .homeabout1 {
    width: 160px !important;
    height: 164px;
  }
  .projectimg1 {
    width: 240px;
    height: 355px;
  }
  .sliderback {
    top: 10%;
  }
}