.aboutback {
    position: relative;
    background-image: url("../../../public/Aboutimg1.webp");
    background-size: cover;
    height: 90vh;
  }
  .circlecontainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
  }
  .circleitem,
  .circleitem1,
  .circleitem2 {
    background: linear-gradient(180deg, #d1de87 0%, #b1deeb 99.99%, #624b32 100%);
    width: 100%;
    height: auto;
    border-radius: 50%;
    position: relative;
    padding: 70px;
  }
  .circleitem {
    left: 50px;
    z-index: 1;
  }
  .circleitem2 {
    position: relative;
    right: 50px;
  }
  .aboutback::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .abouthead {
    color: white;
    font-size: 34px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    position: relative;
    top: 30%;
  }
  .aboutsubhead {
    color: white;
    font-size: 32px;
    font-weight: 400;
    line-height: 42px;
    text-align: center;
    position: relative;
    top: 32%;
  }
  .abouttext {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
  }
  .abouttext2 {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
  }
  .circleitem:hover,
  .circleitem2:hover {
    background: linear-gradient(180deg, #b1deeb 0%, #b1deeb 99.99%, #b1deeb 100%);
  }
  .circleitem1:hover {
    z-index: 1;
    background: linear-gradient(
      180deg,
      rgb(207, 222, 143),
      rgb(207, 222, 143),
      rgb(207, 222, 143) 100%
    );
  }
  .leaderimg {
    width: 355px;
    height: 355px;
    object-fit: contain;
    margin-top: 25px;
    position: relative;
    z-index: 1;
  }
  .leaderborder {
    width: 140%;
    height: 401px;
    border: 2px solid #d1df89;
    position: relative;
    left: -40%;
    padding-left: 38%;
    padding-right: 2%;
    padding-top: 10%;
    box-shadow: 0px 0px 15px 3px #0000001a;
  }
  .abouttext1 {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .icon:hover {
    color: #d8ea61;
  }
  .abouticon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    border: 2px solid black;
  }
  .abouticon:hover {
    color: rgb(116, 98, 64);
    border: 2px solid black;
  }
  .abouthead1 {
    color: #14667e;
    font-size: 22px;
    font-weight: 700;
    line-height: 52px;
    text-align: center;
  }
  @media (max-width: 1240px) {
    .leaderborder {
      padding-top: 6%;
    }
    .circleitem,
    .circleitem1,
    .circleitem2 {
      padding: 50px;
    }
  }
  @media (max-width: 992px) {
    .abouthead1 {
      font-size: 16px;
    }
    .abouttext {
      font-size: 18px;
    }
    .leaderimg {
      top: 2%;
    }
    .leaderborder {
      padding-top: 3%;
      height: auto;
    }
    .abouttext1 {
      font-size: 11px;
      line-height: 22px;
    }
    .aboutsubhead {
      font-size: 24px;
      line-height: 34px;
      padding: 20px;
    }
    .abouttext2 {
      font-size: 10px;
      line-height: 16px;
    }
    .circleitem {
      position: relative;
      left: 30px;
    }
    .circleitem2 {
      position: relative;
      right: 30px;
    }
    .circleitem,
    .circleitem1,
    .circleitem2 {
      height: 300px;
    }
    .abouttext1 {
      font-size: 14px;
    }
  }
  @media (max-width: 767px) {
    .circlecontainer {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      position: relative;
    }
    .circleitem {
      position: relative;
      width: 100%;
      left: 0px;
      bottom: -30px;
    }
    .circleitem1 {
      width: 100%;
    }
    .circleitem2 {
      width: 100%;
      position: relative;
      right: 0px;
      top: -30px;
    }
  
    .abouttext2 {
      font-size: 18px;
      line-height: 32px;
    }
    .leaderimg {
      width: 100%;
      top: 5%;
    }
    .leaderborder {
      width: 100%;
      left: 0;
      padding-left: 4%;
      padding-right: 4%;
      padding-top: 10%;
    }
    .abouttext2 {
      font-size: 16px;
      line-height: 24px;
    }
  }
  @media (max-width: 480px) {
    .circleitem1 {
      z-index: 1;
    }
    .aboutback {
      height: 35vh;
    }
    .abouttext2 {
      font-size: 13px;
      line-height: 24px;
    }
    .aboutsubhead {
      font-size: 14px;
      line-height: 20px;
    }
    .abouthead {
      font-size: 18px;
    }
    .abouthead1 {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 0px;
    }
    .leaderimg {
      width: 90%;
      height: 280px;
      margin: 0 0 10% 5%;
    }
    .leaderborder {
      width: 100%;
      height: auto;
      left: 0%;
      padding: 20% 5% 0 5%;
      margin-top: -30%;
      margin-bottom: 12%;
    }
    .abouttext {
      font-size: 14px;
      line-height: 24px;
    }
  }
  @media (max-width: 376px) {
    .abouttext2 {
      font-size: 12px;
      line-height: 24px;
    }
    .abouthead1 {
      font-size: 20px;
    }
  }
  @media (max-width: 321px) {
    .abouttext {
      font-size: 12px;
      line-height: 17px;
    }
    .abouttext2 {
      font-size: 13px;
      line-height: 21px;
    }
  }
  