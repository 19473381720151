.sectorsback{
    background-image: url('../../../public/sectorsbackimg.webp');
    background-size: cover;
  height: 90vh;
  position: relative;
}
.sectorsback::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 90vh;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .sectorcontainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  .sector-item {
    overflow: hidden;
    width: 100%;
    position: relative;
  }
  .solutionherohead{
font-size: 96px;
font-weight: 800;
line-height: 130.73px;
text-align: center;

  }
  .sectorsherosubhead{
      position: relative;
      color: white;
      font-size: 48px;
      top: 35%;
      text-align: center;
  }
  .sector-img {
    width: 100%;
    height: 60vh;
    filter: brightness(50%);
    transition: transform 1s ease;
    position: relative;
  }
  
  .sector-item:hover .sector-img {
    transform: scale(1.2);
  }
  
  .sectorcontainer:hover .sector-item .sector-img {
    transform: scale(0.8);
  }
  
  .sectorcontainer:hover .sector-item:hover .sector-img {
    transform: scale(1.2);
  }
  .sec-img-text{
    color: white;
    font-size: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }


  @media (max-width:1024px) {
    .sector-img {
      height: 45vh;
    }
   
  }
  @media (max-width:992px) {
    .solutionherohead{
      font-size: 43px;
        }
        .sectorsherosubhead{
            font-size: 32px;
            top: 35%;
        }
    .sector-img {
      height: 40vh;
    }
    .sec-img-text{
      font-size: 32px;
    }
  }
  @media (max-width:480px) {
    .sectorsback{
      background-image: url('../../../public/sectorsbackimg.webp');
      background-size: cover;
    height: 35vh;
    padding-bottom: 3%;
  }
    .sectorsback::before {
      height: 35vh;
    }
    .sectorsherosubhead {
      font-size: 18px;
      top: 20%;
      padding: 10%;
  }
  .sectorcontainer {
    grid-template-columns: repeat(1, 1fr);
  }
  .sector-img {
    height: 30vh;
}
  }