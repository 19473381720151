.productback {
    background-image: url("../../../public/productheroimg.webp");
    background-size: cover;
    height: 90vh;
    background-repeat: no-repeat;
  }
  .productback::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 90vh;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .productbackcolor {
    background-color: rgba(177, 222, 235, 0.2);
    background-size: cover;
    height: auto;
    background-repeat: no-repeat;
  }
  .productherohead {
    color: white;
    font-size: 64px;
    font-weight: 800;
    line-height: 87.16px;
    text-align: center;
    position: relative;
    top: 40%;
  }
  
  
  .productimg {
    width: 100% ;
    height: 600px ;
    object-fit: cover contain ;
  }
  
  .producthead{
  font-size: 32px;
  font-weight: 600;
  line-height: 49.03px;
  text-align: left;
  color: #5A5959;
  }
  .producttext{
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #5A5959;
  }
  .boldproducttext{
    font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
  color: #000000;
  }
  .more-icon{
    font-size: 12px;
  }
  .read_btn{
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  color: #303030;
  text-decoration: underline;
  margin-top: 3%;
  border: none;
  background-color: transparent;
  }
  .service_rev{
   margin-top: 5%;
   margin-bottom: 5%;
          }
          .productcard{
            width: 100%;
            height: 425px;
            border: 1px solid #C1BDBD;
            background-color: #FFFEFF;
          }
          .procardimg{
            width: 100%;
            height: 201px;
            object-fit: cover;
          }
          .product_textdiv{
            width: 100%;
            height: 170px;
          }
          .product_seediv{
            width: 100%;
            height: 30px;
          }
          .procard_mob_div{
            display: none;
          }
  
  @media (max-width:1024px) {
    .service_imgdiv{
      width: 100% !important;
      height: 450px !important;
    }
  }
  
  
  @media (max-width:992px) {
    .productimg {
      width: 100%;
      height: 360px;
    }
    .producthead{
      font-size: 28px;
      line-height: 38.03px;
      text-align: left;
      }
      .producttext{
        font-size: 14px;
        line-height: 22px;
        }
        .service_imgdiv{
          width: 100% !important;
          height: 400px !important;
        }
        
  }
  @media (max-width:768px) {
    .service_rev{
      flex-direction: column-reverse;
    }
  }
  @media (max-width:480px) {
    .procard_mob_div{
      display: block;
    }
    .procard_desk_div{
      display: none;
    }
    .productback {
      height: 300px;
    }
    .productback::before {
      width: 100%;
      height: 300px;
    }
    .productherohead {
      font-size: 28px;
      line-height: 38.13px;
      top: 40%;
    }
    .productimg {
      width: 100%;
      height: 180px;
      margin-bottom: 2%;
    }
    .producthead{
      margin-top: 2%;
      font-size: 16px;
      line-height: 21.79px;
      text-align: left;
      }
      .producttext{
        font-size: 12px;
        line-height: 25px;
        }
        .service_rev{
  flex-direction:column-reverse !important;
        }
        .service_imgdiv{
          width: 100% !important;
          height: 200px !important;
        }
        .read_btn{
          font-size: 12px;
          }
          .productbackcolor {
            padding-top: 20px;
          }
  }