.careerback{
    background-image: url('../../../public/career_back.webp');
    background-size: cover;
    width: 100%;
    height: 90vh;
    background-repeat: no-repeat;
}
.careerback::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 90vh;
    background-color: rgba(0, 0, 0, 0.6) !important;
  }
  .career_title{
    position: relative;
font-size: 96px;
font-weight: 800;
line-height: 130.73px;
text-align: center;
color: #FFFFFF;
top: 40%;
  }
  .career_head{
font-size: 36px;
font-weight: 700;
line-height: 49.03px;
text-align: center;
color: #14667E;
  }
  .carrer_colorhead{
    font-size: 36px;
font-weight: 700;
line-height: 49.03px;
text-align: center;
color: #899450;
  }
  .career_text{
font-size: 24px;
font-weight: 400;
line-height: 40px;
text-align: center;
color: #494949;
  }
  .carrer_text_div{
    padding-left: 165px;
    padding-right: 165px;
  }
  .career_linkhead{
font-size: 24px;
font-weight: 800;
line-height: 32.68px;
text-align: center;
color: #14667E;
  }
  .career_colorlink{
    font-size: 24px;
font-weight: 800;
line-height: 32.68px;
text-align: center;
color: #000000;
text-decoration: underline;
  }
  @media (max-width:1024px) {
    .carrer_text_div{
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  @media (max-width:992px) {
    .careerback{
      width: 100%;
      height: 80vh;
  }
  .careerback::before {
      width: 100%;
      height: 80vh;
    }
    .career_title{
  font-size: 60px;
  top: 30%;
    }
    .career_head{
      font-size: 28px;
      line-height: 40px;
        }
        .carrer_colorhead{
          font-size: 28px;
      line-height: 40px;
        }
        .career_text{
      font-size: 14px;
      line-height: 25px;
        }
        .career_linkhead{
          font-size: 20px;
          line-height: 30px;
            }
            .career_colorlink{
              font-size: 20px;
          line-height: 30px;
            }
  }

  @media (max-width:480px) {
    .careerback{
      width: 100%;
      height: 40vh;
  }
  .careerback::before {
      width: 100%;
      height: 40vh;
    }
    .carrer_text_div{
      padding-left: 20px;
      padding-right: 20px;
    }
    .career_title{
      font-size: 40px;
      top: 25%;
        }
        .career_head{
          font-size: 20px;
          line-height: 27.24px;
            }
            .carrer_colorhead{
              font-size: 20px;
              line-height: 27.24px;
            }
            .career_linkhead{
              font-size: 16px;
              line-height: 21.79px;
                }
                .career_colorlink{
                  font-size: 16px;
                  line-height: 21.79px;
                }
                .career_text{
                  font-size: 12px;
                  line-height: 20px;
                    }
  }