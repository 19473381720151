.Navdiv {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      90deg,
      #372615 17.48%,
      #816c46 67.16%,
      #241d16 106.08%
    );
    position: sticky;
    top: 0;
    box-shadow: 0px 4px 4px 0px #816c464d;
    z-index: 999;
  }
  .active {
    color: #ff7927f2 !important;
  }
  .navlogo {
    width: 130px;
    height: auto;
    top: 10px;
    position: absolute;
    z-index: 1;
    left: 25px;
    object-fit: contain;
  }
  .navcontainer{
    display: grid;
    grid-template-columns: 28% 7% 7% 9% 15% 8% 5% 9% 12%;
  }
  .navtext {
    margin-right: 10px;
    font-size: 1em;
    font-weight: 500;
    line-height: 20px;
    color: #ffffff;
    position: absolute;
    letter-spacing: 0.23999999463558197px;
    margin: 0;
    top: 30px;
  }
  .navtext:hover {
    color: #ff7927f2;
  }
  
  .nav-container {
    display: flex !important;
  }
  .input-container {
    top: 22px;
    position: relative;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    right: 20px;
  }
  @media (max-width:1200px) {
    .navcontainer{
      display: grid;
      grid-template-columns: 20% 7% 7% 10% 17% 8% 6% 10% 12%;
    }
  }
  .navsearch {
    width: 16px;
    height: 25px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
  }
  
  .navinput {
    background: none;
    border: none;
    width: 160px;
    padding-left: 30px;
    border: 1.5px solid #fff;
    height: 34px;
    color: white;
  }
  .navinput::placeholder {
    color: white !important;
  }
  .navbars {
    display: none;
  }
  .menu {
    display: none;
  }
  @media (max-width: 992px) {
    .navlogo {
      width: 125px;
    }
    .navtext {
      display: none;
    }
    .navinput {
      display: none;
    }
    .navsearch {
      width: 30px;
      top: 20px;
      left: -20px;
    }
    .navbars {
      display: block;
      width: 30px;
      height: 30px;
      color: white;
      top: 3px;
      right: 10px;
      position: absolute;
    }
    .menu {
      display: block;
      width: 200px !important;
      height: 340px;
      position: fixed;
      right: 0;
      background: linear-gradient(
        90deg,
        #372615 -28.24%,
        #816c46 17.32%,
        #241d16 95.96%
      );
      top: 80px;
      z-index: 999;
      padding: 20px;
      opacity: 0.9;
    }
    .navtext0 {
      font-size: 1em;
      font-weight: 500;
      line-height: 30px;
      color: #ffffff !important;
      text-align: center;
    }
    .navtext0:hover {
      color: #ff7927f2 !important;
    }
  }
  @media (max-width: 767px) {
    .navsearch {
      top: 20px;
      left: -20px;
    }
    .navbars {
      display: block;
      right: 10px;
    }
  }
  @media (max-width: 480px) {
    .navsearch {
      top: 10px;
      left: -25px;
      width: 20px;
      height: 20px;
    }
    .navbars {
      display: block;
      width: 25px;
      height: 25px;
      top: -3px;
      right: -5px;
    }
    .navlogo {
      width: 80px;
      height: auto;
      top: 13px;
    }
    .Navdiv {
      height: 60px;
    }
    .menu {
      top: 60px;
      height: 340px;
    }
  }
  