.zeroliquidback{
    background-image: url('../../../public/solutionimg3.webp');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 90vh;
    position: relative;
}
.zeroliquidback::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    background-color: rgba(0, 0, 0, 0.2);
  }
  .supply_title{
    font-size: 48px;
    font-weight: 800;
    line-height: 65.37px;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    top: 35%;
    }
    .supplytext{
        font-size: 16px;
        font-weight: 400;
        line-height: 37px;
        text-align: center;
        color: #5A5959;
        }