
.contactback{
    background-image: url("../../../public/contactheroimg.webp");
    background-size: cover;
    height: 90vh;
}
.contactback::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 90vh;
    background-color: rgba(0, 0, 0, 0.6) !important;
  }
  .contactherohead{
    position: relative;
    color: white;
    font-size: 88px;
    font-weight: 700;
    line-height: 130.73px;
    text-align: center;
    top: 38%;
  }
  .contactborder{
margin-top: 4%;
    border: 1px solid #FFFFFF;
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 4px 3px #0000000D;
  }
  .formhead{
font-size: 48px;
font-weight: 600;
line-height: 65.37px;
text-align: center;
color: #5A5959;
  }
  .contactinput{
    border: 2px solid #C5C3C3;
    width: 49%;
    height: 40px;
    padding-left: 10px;
  }
  .contactinput::placeholder{
    color: #716E6E !important;
  }
  .contacttextarea{
    border: 2px solid #C5C3C3;
    width: 100%;
    height: 140px;
    padding: 10px;
  }
  .contacttextarea::placeholder{
    color: #716E6E !important;
  }
  .contactbutt{
    width: 187px;
    height: 48px;
    background-color: #816C46;
    color: white;
    font-size: 20px;
    font-weight: 600;
    line-height: 32.68px;
    text-align: center;
  }
  .contactsearch{
    width: 11px;
    height: 18px;
    margin-top: 12px;
    margin-right: 5px;
  }
  .contacthead{
font-size: 22px;
font-weight: 700;
line-height: 40px;
text-align: left;
color: #14667E;
  }
.contacttext{
font-size: 18px;
font-weight: 400;
line-height: 32px;
text-align: left;
color: #494949;
}
.contacttext1{
    color: #576605;
    text-decoration: underline #576605;
}
.message-head{
  font-size: 16px;
  font-weight: 700;
}
.contact_image{
  width: 557px;
  height: 435px;
  object-fit: contain;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
  background: linear-gradient(90deg, #D1DE87 0%, #B1DEEB 99.99%, #624B32 100%);
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
}

.close {
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 0;
  right:  20px;
  cursor: pointer;
}
@media (max-width:1024px) {
  .contact_image{
    width: 100%;
    height: 400px !important;
    object-fit: contain !important;
  }
}
@media (max-width:992px) {
  .contactback{
    height: 70vh;
}
.contactback::before {
  height: 70vh;
}
.contactherohead{
  font-size: 60px;
  line-height: 90px;
  top: 35%;
}
.formhead{
  font-size: 38px;
  line-height: 62px;
    }
    .contacthead{
      font-size: 20px;
        }
      .contacttext{
      font-size: 16px;
      line-height: 22px;
      }
}
@media (max-width:480px) {
.formhead {
  font-size: 24px;
}
.contacthead{
  font-size: 14px;
  line-height: 20px;
    }
.contacttext{
  font-size: 12px;
  line-height: 18px;
  }
  .contactsearch{
    margin-top: 1px;
  }
.contactback {
  height: 35vh;
}
.contactback::before {
  height: 35vh;
}
.contactherohead {
  font-size: 32px;
  top: 25%;
}
.contactbutt{
  width: 164px;
  height: 45px;
  font-size: 18px;
  margin-bottom: 5%;
}
.contact_image{
  height: 240px !important;
}
}