.commonback {
    background-size: cover;
    height: 90vh;
    position: relative;
  }
  
  .urbanback {
    background-image: url("../../../public/sectorsimg1.webp");
  }
  
  .infraback {
    background-image: url("../../../public/sectorsimg2.webp");
  }
  
  .industryback {
    background-image: url("../../../public/sectorsimg3.webp");
  }
  
  .commonback::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  
  .sechead {
    color: white;
    font-size: 92px;
    text-align: center;
    position: absolute;
    top: 40%;
    width: 100%;
    font-weight: 600;
  }
  .urbancontainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
  }
  .urbancontainer1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
  .urbancontainer2{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
  }
  .urbanimg {
    width: 100%;
    height: 425px;
    object-fit: cover contain;
    filter: brightness(60%);
    position: relative;
  }
  .urbanimg1 {
    width: 100%;
    height: 525px;
    object-fit: contain cover;
    filter: brightness(60%);
    position: relative;
  }
  .urbantext {
    width: 300px;
    font-size: 28px;
    font-weight: 700;
    line-height: 30.03px;
    text-align: center;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .urbantext1 {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.03px;
    text-align: center;
    color: white;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .urbantext1:hover {
    text-decoration: underline;
  }
  .item_text {
    list-style-type: disc;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #494949;
  }
  
  .modal {
    display: flex;
    justify-content: center;
    position: fixed ;
    z-index: 1 ;
    overflow: auto ;
    scrollbar-width: none;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
    padding-top: 8%;
    height: 100%;
  }
  
  .modal-sectorcontent {
  background-image: linear-gradient(90deg, #d1de87 0%, #b1deeb 99.99%, #624b32 100%);
    padding: 20px;
    border: 1px solid #888;
    width: 95%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    position: relative;
  }
  
  .closex {
    color: black;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 0;
    right: 20px !important;
  }
  
  .closex:hover,
  .closex:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .hotels_img {
    width: 100%;
    height: 450px;
    object-fit: cover contain;
  }
  .no-scroll {
    overflow: hidden;
  }
  .hotels_logo {
    width: 100px;
    height: 100px;
    object-fit: contain;
    aspect-ratio: 3/2 !important;
  }
  .urban_title {
    font-size: 28px;
    font-weight: 600;
    color: #000000;
  }
  .hotels_content {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #494949;
  }
  .hotels_head {
    font-size: 22px;
    font-weight: 600;
    line-height: 29.96px;
    text-align: left;
    color: #303030;
  }
  .client-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
  }
  @media (max-width: 1024px) {
    .modal {
      padding-top: 10%;
    }
  }
  
  @media (max-width: 992px) {
    .modal {
      padding-top: 12%;
    }
    .urbantext {
      font-size: 18px;
      line-height: 30.03px;
    }
    .urbanimg {
      width: 100%;
      height: 350px;
    }
    .hotels_img {
      width: 100%;
      height: 400px;
    }
    .urban_title {
      font-size: 24px;
      line-height: 20px;
      margin-bottom: 2%;
    }
    .hotels_content {
      margin-top: 15px;
      font-size: 14px;
      line-height: 22px;
    }
    .hotels_head {
      font-size: 20px;
      line-height: 28px;
    }
    .item_text {
      font-size: 14px;
      line-height: 22px;
    }
  }
  @media (max-width: 480px) {
    .modal {
      padding-top: 18%;
    }
    .commonback {
      height: 40vh;
    }
    .urbancontainer {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      /* width: 100%; */
      margin-top: 40px;
    }
    .urbancontainer1 {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
    .urbancontainer2 {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
    .sechead {
      font-size: 28px;
    }
    .urbanimg {
      width: 100%;
      height: 320px;
    }
    .urbantext {
      font-size: 18px;
    }
    .urbantext1 {
      font-size: 12px;
    }
    .hotels_img {
      width: 100%;
      height: 250px;
    }
    .hotels_content {
      font-size: 12px;
      line-height: 20px;
    }
    .hotels_head {
      font-size: 18px;
      line-height: 26px;
    }
    .item_text {
      margin-top: 6px;
      font-size: 12px;
      line-height: 20px;
    }
    .urbanimg1 {
      width: 100%;
      height: 360px;
    }
  }
  
  @media (max-width: 320px) {
    .modal {
      padding-top: 20%;
    }
  }
  