.footback {
    width: 100%;
    height: 301px;
    background-image: linear-gradient(
      90deg,
      #33271a -0.25%,
      #7a5d3e 75.26%,
      #3f3121 100%
    );
    padding: 0px 40px;
  }
  .footerlogo {
    width: 108px;
    height: 51px;
  }
  .footertext {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #ffff;
  }
  .footeraddress{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #ffff;
    list-style: none;
  }
  .footeraddress:hover{
    color: #D8EA61;
  }
  .footertext:hover {
    color: #D8EA61;
  }
  .footertext1:hover {
    color: #D8EA61;
  }
  .foothead {
    color: white;
    font-size: 22px;
    font-weight: 500;
    line-height: 29.96px;
    text-align: left;
  }
  .footertext1 {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    color: #ffff;
    list-style: none;
  }
  .icon {
    width: 7px;
    margin-right: 20px;
    object-fit: contain;
  }
  .icon1{
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .icon1:hover{
    color: #D8EA61;
  }
  .footback2 {
    background-color: #53402b;
    color: white;
  }
  .foottext2 {
    font-size: 22px;
    font-weight: 400;
    line-height: 29.96px;
    text-align: center;
    margin-top: 12px;
  }
  .locationicon{
    margin-left: 2px;
  }
  @media (max-width:992px) {
    .footback {
      width: 100%;
      height: 301px;
      background-image: linear-gradient(90deg, #33271a -0.25%, #7a5d3e 75.26%, #3f3121 100%);
      padding: 0px 20px;
  }
  .footertext {
    font-size: 10px;
    line-height: 22px;
  }
  .footeraddress{
    font-size: 10px;
    line-height: 18px;
  }
  .footertext1 {
    font-size: 10px;
    line-height: 32px;;
  }
  }
  @media (max-width: 767px) {
    .footback {
      width: 100%;
      height: auto;
      background-image: linear-gradient(
        90deg,
        #33271a -0.25%,
        #7a5d3e 75.26%,
        #3f3121 100%
      );
      padding: 0px 40px;
    }
    .foottext2 {
      font-family: Open Sans;
      font-size: 10px;
      font-weight: 400;
      line-height: 13.62px;
      text-align: center;
    }
  }
  @media (max-width:480px) {
    .footback {
      padding: 20px 40px;
  }
  }