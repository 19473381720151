.solution_card {
    width: 100%;
    height: 350px;
    transform-style: preserve-3d;
    transition: transform 0.9s;
  }
  .solution_card:hover {
    transform: rotateY(180deg);
  }
  .sol_card_front,
  .sol_card_back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  .sol_card_back {
    transform: rotateY(180deg);
  }
  .sol_card_back::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #2B5975;
    opacity: 85%;
  }
  .sol_cont_div {
    position: relative;
    bottom: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  .sol_img {
    width: 100%;
    height: 100%;
  }
  .sol_text_div {
    width: 100%;
    height: 70px;
    background-image: linear-gradient(221.56deg, #000000 30.2%, rgba(45, 18, 18, 0) 99.63%);
    position: relative;
    bottom: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sol_head,
  .sol_text {
    color: #FFFFFF;
    text-align: center;
    font-weight: 600;
  }
  .sol_head {
    font-size: 20px;
    line-height: 28px;
  }
  .sol_text {
    font-size: 16px;
    line-height: 35px;
  }
  .sol_mob_div {
    display: none !important;
  }
  
  @media (max-width: 1024px) {
    .solution_card {
      height: 300px;
    }
    .sol_text_div {
      height: 100px;
      bottom: 100px;
      padding: 2%;
    }
    .sol_head {
      font-size: 18px;
      line-height: 24px;
    }
    .sol_text {
      font-size: 14px;
      line-height: 24px;
    }
    .sol_cont_div {
      padding: 15px;
    }
  }
  
  @media (max-width: 992px) {
    .solution_card {
      margin-bottom: 5%;
      height: 320px;
    }
    .sol_text_div {
      height: 80px;
      bottom: 80px;
    }
  }
  
  @media (max-width: 480px) {
    .sol_mob_div {
      display: block !important;
    }
    .sol-desk_div {
      display: none !important;
    }
    .solution_card {
      height: 300px !important;
    }
  }
