.solutionheroback,
.innerpageheroback,
.treatmentback,
.supplyback,
.zeroliquidback {
  background-size: cover;
  object-fit: cover;
  height: 90vh;
  position: relative; 
}

.solutionheroback {
  background-image: url("../../../public/solutionheroimg.webp");
}

.innerpageheroback {
  background-image: url("../../../public/solutionimg1.webp");
}
.treatmentback{
  background-image: url('../../../public/solutionimg4.webp');
}

.solutionheroback::before,
.innerpageheroback::before,
.treatmentback::before,
.supplyback::before,
.zeroliquidback::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  background-color: rgba(0, 0, 0, 0.2);
}
.solutionherohead,
.solutionherosubhead,
.solutionherohead1 {
  text-align: center;
  color: white;
  position: relative;
  top: 35%;
}
.supplyback{
    background-image: url('../../../public/solutionimg2.webp');
}


.zeroliquidback{
  background-image: url('../../../public/solutionimg3.webp');
}

.solutionherohead {
  font-size: 92px;
  font-weight: 700;
  line-height: 130.73px;
}
.solutionherohead1 {
  font-size: 68px;
  font-weight: 700;
  line-height: 130.73px;
}
.solutionherosubhead {
  font-family: "Open Sans", sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 30px;
}
.solutionimage {
  display: block;
  width: 100%;
  height: 220px;
}
.cont {
  position: relative;
  width: 100%;
  height: auto;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #1b57519d;
}

.cont:hover .overlay {
  opacity: 1;
}

.text1 {
  width: 80%;
  color: white;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.solutioncard {
  width: 100%;
  height: 300px;
}
.cardbottom {
  background-color: #d1de87;
  height: 80px;
  padding: 10px;
}
.cardtext {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  color: #0a4c5f;
}
.solinnertext {
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
}

.list1{
  height: 80px;
  width: auto;
}

 .list{
  height: 80px;
  margin-left: 20%;
 }
.list_img{
  width: 60px;
  height: 40px;
}
.listtext {
  font-size: 20px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  color: #14667e;
}
.overlay_item{
  position: relative;
 top: -25%;
 transform: translate(9%, -50%);
}
.listhead {
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  text-align: left;
}
.listtext1 {
  font-size: 16px;
  line-height: 21.79px;
  text-align: left;
}
.liback {
  background-color: #E0F2F7;
  width: 100%;
  height: 126px;
  padding: 10px 100px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.approachhead{
font-size: 20px;
font-weight: 700;
line-height: 27.24px;
text-align: left;
}
.approachtext{
  font-size: 20px;
font-weight: 400;
line-height: 27.24px;
text-align: left;
}
.tick{
  width: 18px;
height: 14.87px;
margin-top: -10px;
}

.Wqatext{
font-size: 24px;
font-weight: 400;
line-height: 32.68px;
text-align: left;
color: #5A5959;
}
.lastimg{
  width: 100%;
height: 458px;
object-fit: contain cover;
}
.totallastdiv{
  width: 100%;
height: 178px;
box-shadow: 2px 2px 4px 0px #14667E;
padding: 30px;
margin-top: 3%;
margin-bottom: 5%;
}
.totallastdiv:hover{
 background-color: #4F8C9E;
}
.totallastdiv:hover .lasttotalhead,
.totallastdiv:hover .lasttotaltext {
    color: #FFFFFF;
}
.lasttotalhead{
font-size: 16px;
font-weight: 700;
line-height: 27.24px;
text-align: center;
color: #000000;
}
.lasttotaltext{
  font-size: 16px;
  line-height: 27.24px;
  text-align: center;
  color: #5A5959;
  }
 
  .adv_service_mob{
    display: none;
  }

  .adv_service_desk{
    border-top: 5px solid #14667e;
    border-right: 5px solid #14667e;
    border-bottom: 5px solid #14667e;
    width: 100%;
    height: auto;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 50px;
  }

  .greendiv{
    margin-bottom: 6%;
    width: 100%;
    height: 77px;
    background-color: #D1DE87;
    box-shadow: 0px 2px 4px 0px #00000033;
padding-left: 10%;
    align-items: center;
    overflow: hidden;
  }
  .greendiv2{
    width: 0px; 
  height: 0px;
  position: relative; 
  bottom: 10px;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 60px solid #D1DE87;
  overflow: hidden;
  /* transition: height 0.3s ease; */

  }
  .expanded2 {
    width: 0px; 
  height: 0px;
    position: relative; 
    bottom: 10px;
    border-top: 90px solid transparent;
    border-bottom: 90px solid transparent;
    border-left: 80px solid #D1DE87;
  }
  .expanded3 {
    width: 0px !important; 
  height: 0px !important;
    position: relative !important; 
    bottom: 10px !important;
    border-top: 90px solid transparent !important;
    border-bottom: 90px solid transparent !important;
    border-left: 80px solid #B1DEEB !important;
  }
  
  .bluediv{
    margin-bottom: 6%;
    width: 100%;
    height: 77px;
    background-color: #B1DEEB;
    box-shadow: 0px 2px 4px 0px #00000033;
padding-left: 10%;
align-items: center;
overflow: hidden;
/* transition: height 0.3s ease; */
  }
  .bluediv2{
    width: 0px; 
  height: 0px;
  position: relative; 
  bottom: 10px;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 60px solid #B1DEEB;
  overflow: hidden;
  /* transition: height 0.3s ease; */
  }
  .greentext{
font-size: 28px;
font-weight: 600;
line-height: 38.13px;
text-align: left;
color: #000000;
  }
  .greentext1{
font-size: 14px;
font-weight: 400;
line-height: 22px;
text-align: left;
color: #000000;
  }
  .arrow_down{
    width: 24px;
    height: 12px;
  }
  
  .collapsed {
    height: 77px; 
  }
  
  .expanded {
    height: 156px;
  }

  
  .hidden {
    display: none;
  }
  
  .visible {
    display: block;
  }

  @media (max-width:1024px) {
    .overlay_item{
     transform: translate(16%, -50%) !important;
    }
    .totallastdiv{
      width: 100%;
    height: 180px;
    padding: 10px;
    }
    .greentext{
      font-size: 24px;
        }
        .greendiv{
          margin-bottom: 8%;
      padding-left: 5%;
        }
        .bluediv{
          margin-bottom: 8%;
          padding-left: 5%;
            }
  }
 
  @media (max-width:992px) {
    .overlay_item{
      transform: translate(23%, -50%) !important;
     }
    .solutionherohead {
      font-size: 48px;
      line-height: 65px;
    }
    .solutionherosubhead {
      font-size: 32px;
      font-weight: 600;
    }
    
    .listtext {
      font-size: 16px;
    }
    .listhead {
      font-size: 14px;
      line-height: 21.79px;
    }
    .listtext1 {
      font-size: 14px;
      line-height: 19.07px;
    }
    .approachhead{
      font-size: 16px;
      line-height: 22px;
      }
      .approachtext{
        font-size: 16px;
      line-height: 22px;
      }
      .totallastdiv{
        width: 100%;
      height: 200px;
      padding: 20px;
      }
      .lasttotalhead{
        font-size: 14px;
        line-height: 20px;
        }
        .lasttotaltext{
          font-size: 14px;
          line-height: 22px;
          }
          .greendiv{
            margin-bottom: 10%;
      
          }
          .bluediv{
            margin-bottom: 10%;
         
              }
          .greentext{
            font-size: 20px;
              }
              .arrow_down{
                width: 20px;
                height: 10px;
                margin-left: 1%;
              }
              .greentext1{
                font-size: 12px;
                line-height: 18px;
                  }
  }

@media (max-width: 480px) {
  .solutionherohead1 {
    top: 40%;
    font-size: 22px;
    line-height: 32px;
}
  .innerpageheroback {
    height: 30vh;
  }
  .solutionherohead {
    font-size: 28px;
    line-height: 38.13px;
    top: 30%;
  }
  .solutionheroback,
  .innerpageheroback,
.treatmentback,
.supplyback,
.zeroliquidback{
    height: 35vh !important;
  }
  .supply_title {
    font-size: 24px !important;
    line-height: 35.37px !important;
}
.supplytext {
  line-height: 24px !important;
}
  .solutionherosubhead {
    font-size: 16px;
    line-height: 20px;
    top: 30%;
  }
  .solutionheroback::before {
    height: 35vh;
  }
  .solinnertext {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
  .arrowtext{
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }
 
.Wqatext{
  font-size: 20px;
  font-weight: 400;
  line-height: 27.24px;
  margin-bottom: 25px;
  }
.listhead {
  font-size: 12px;
  line-height: 16.34px;
}
.listtext1 {
  font-size: 12px;
line-height: 16.34px;
padding-right: 2%;
padding-left: 15%;
}
.lastimg{
  width: 100%;
height: 281px;
object-fit: contain cover;
margin-bottom: 5%;
}
.flexii{
  flex-direction: column-reverse;
}
.adv_service_mob{
  display: block;
}
.adv_service_desk{
  display: none;
}
.adv_mob_head{
  background-image: url("../../../public/service_bg.webp");
  background-size: contain;
  background-repeat: no-repeat;
  width: 99% !important;
  height: 50px;
  display: flex;
align-items: center;
padding-left: 15%;
}
.listtext{
  font-size: 12px;
  font-weight: 700;
  line-height: 16.34px;
  text-align: center;
color: #000000; 
margin-top: 6px;
margin-right: 1%; 
}
.liback {
  height: 126px;
  padding: 10px 20px;
}
.approachhead{
  font-size: 12px;
  line-height: 20px;
  }
  .approachtext{
    font-size: 12px;
  line-height: 20px;
  }
  .tick{
  margin-top: -20px;
  }
  .totallastdiv{
    width: 100%;
  height: 182px;
  padding: 30px;
  }
  .arrow_down{
    width: 18px;
    height: 8px;
    margin-bottom: 2%;
  }
  .greendiv{
    margin-bottom: 12%;

  }
  .bluediv{
    margin-bottom: 12%;
      }
      .greentext{
        font-size: 16px;
          }
          .cardtext {
            margin-top: 15px;
        }
        .supply_title {
          font-size: 24px !important;
          line-height: 35.37px !important;
      }
      .supplytext {
        line-height: 24px !important;
      }
      .greentext1 {
        margin-top: -17px;
    }
}
@media (max-width:380px) {
  .greentext {
    font-size: 12px;
}
}
@media (max-width:320px) {
  .adv_mob_head{
    height: auto;
    padding-left: 5%;
  }
  .listtext1 {
    font-size: 12px;
  line-height: 16.34px;
  padding-right: 2%;
  padding-left: 5%;
  }
  .liback {
    display: flex;
    align-items: center;
     font-size: 12px;
     line-height: 15.07px;
   }
   .totallastdiv{
    width: 100%;
  height: 195px;
  padding: 20px;
  }
}