.blogback {
    background-image: url("../../../public/blogheroimg.webp");
    height: 90vh;
    background-size: cover;
  }
  .blogback::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 90vh;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .blogherohead {
    color: white;
    font-size: 96px;
    font-weight: 800;
    line-height: 130.73px;
    text-align: center;
    position: relative;
    top: 35%;
  }
  
  .blog-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
  }
  .blogcards{
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 4px 1px #00000026;
    border-radius: 5px;
    padding: 20px;
    height: 420px;
    width: 100%;
  }
  .blogcards:hover{
  background-color: #CDD9E1;
  }
  
  .blogcard_img{
    width: 100%;
    height: 260px;
  }
  .post-image{
    width: 100%;
    height: 100%;
  }
  .blogcard_text_div{
    width: 100%;
    height: 160px;
  }
  .blog_head{
    font-family: Open Sans;
  font-size: 16px;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #303030;
  margin-top: 5%;
  }
  .blog_read{
    font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  color: #303030;
  text-decoration: underline;
  margin-top: 5%;
  }
  .more-icon{
    font-size: 12px;
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pagination-button {
   width: 61px;
   height: 26.26px;
   border: 1px solid #303030;
    margin: 0 10px;
    cursor: pointer;
  font-size: 10px;
  font-weight: 400;
  line-height: 13.62px;
  text-align: center;
  color: #303030;
  }
  .page-button{
    width: 40px;
    height: 36px;
    border: 1px solid #000000;
  font-size: 24px;
  font-weight: 400;
  line-height: 32.68px;
  text-align: center;
  color: #303030;
  }
  .page-button.active {
    color: #816C46;
  }
  .blog_container_mob{
    display: none;
  }
  @media (max-width:1024px) {
    .blog-container{
      grid-gap: 30px;
    }
    .blogcards{
      height: 380px;
    }
    .blogcard_img{
      width: 100%;
      height: 230px;
    }
    .blogcard_text_div{
      width: 100%;
      height: 150px;
    }
  }
  @media (max-width:992px) {
    .blog-container{
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media (max-width:480px) {
    .blog_container_desk{
    display: none;
    }
    .blog_container_mob{
      display: block;
    }
    .blog-container{
      grid-template-columns: repeat(1, 1fr);
    }
      .blogback {
        height: 35vh;
    }
    .blogback::before {
      height: 35vh;
    }
    .blogherohead {
      font-size: 40px;
      top: 30%;
    }
    .pagination {
      margin-bottom: 20px;
    }
  }